import React from "react"

const browser = typeof window !== "undefined" && window

const ErrorPage = () => {
    return (
        browser && (
            <div>
                <h1>404 Error content...</h1>
            </div>
        )
    )
}

export default ErrorPage
